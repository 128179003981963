<template>
<div>
     <div class="dashboard-widget-tabs dashboard-widget-pages">
        <div class="dashboard-widget-tabs-content padding-m" style="margin: 0;border: 0;padding: 0;">
            <h2>
                <!-- <i class="uil uil-create-dashboard"></i> -->
                <svg class="rightnav-svg rightnav-svg-page" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">viewBox="0 0 512 512">
                    <path class="fa-primary" d="M408 360H472C485.3 360 496 370.7 496 384C496 397.3 485.3 408 472 408H408V472C408 485.3 397.3 496 384 496C370.7 496 360 485.3 360 472V408H296C282.7 408 272 397.3 272 384C272 370.7 282.7 360 296 360H360V296C360 282.7 370.7 272 384 272C397.3 272 408 282.7 408 296V360z" />
                    <path class="fa-secondary" d="M32 80C32 53.49 53.49 32 80 32H176C202.5 32 224 53.49 224 80V176C224 202.5 202.5 224 176 224H80C53.49 224 32 202.5 32 176V80zM32 336C32 309.5 53.49 288 80 288H176C202.5 288 224 309.5 224 336V432C224 458.5 202.5 480 176 480H80C53.49 480 32 458.5 32 432V336zM432 32C458.5 32 480 53.49 480 80V176C480 202.5 458.5 224 432 224H336C309.5 224 288 202.5 288 176V80C288 53.49 309.5 32 336 32H432z" /></svg>
                <span class="page-title-svg">مدیریت داشبورد‌ها</span>
            </h2>
            <div class="dashboard-widget-h-btns">
                <a v-if="!grid" href="" class="m-left m-top tgju-btn tgju-btn-primary hide-pages-mobile-btn" @click.prevent="open_dashboard_add_modal()" v-tooltip="'ایجاد داشبورد جدید'"><i class="uil uil-plus-circle"></i> <span class="hide-m-min">ایجاد داشبورد</span></a>
                <a v-if="!grid" href="" @click.prevent="cahnge_style()" class="m-left m-top tgju-btn tgju-btn-light btn-icon-i" v-tooltip="'نوع نمایش'"><i class="fa fa-th-large"></i></a>
                <a v-if="grid" href="" @click.prevent="cahnge_style()" class="m-left m-top tgju-btn tgju-btn-light btn-icon-i" v-tooltip="'نوع نمایش'"><i class="fa fa-bars"></i></a>
                <a href="" @click.prevent="open_help_modal()" class="m-left m-top tgju-btn tgju-btn-light tgju-help-btn" v-tooltip="'راهنما'"><i class="uil uil uil-question-circle"></i></a>
            </div>
        </div>
    </div>

    <div class="mobile-btns-box show-pages-mobile-btn one-btn">
        <span @click.prevent="open_dashboard_add_modal()" class="tgju-btn tgju-btn-primary" v-tooltip="'داشبورد جدید'">
            <i class="uil uil-plus-circle"></i>
            <span> ایجاد داشبورد جدید </span>
        </span>
    </div>

    <slot v-if="grid">
        <slot v-if="loading">
            <div class="tgju-widget-content full-padding">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </slot>
        <slot v-else>
            <slot v-if="dashboards.length">
                <div class="dashboard-grid-boxs">
                    <div class="row tgju-widgets-row">
                        <div class="col-dash col-12 col-md-6 col-lg-4 col-x-3 col-xl-2" v-for="dashboard in dashboards" :key="dashboard.id" @click.prevent="goToRoute(dashboard.id)">
                            <div class="dashboard-grid-box" @click.stop="goToDashboardRoute(dashboard.id)">
                                <div class="dashboard-grid-image" style="background-image: url(https://static.tgju.org/views/default/images/dashboard-grid-box-bg.png?03);background-repeat: no-repeat;background-size: cover;background-position: center;">
                                    <div class="dashboard-grid-last-update">
                                        <span>آخرین بروز رسانی</span>
                                        <time>{{ toRelative(dashboard.updated_at) }}</time>
                                    </div>
                                </div>
                                <div class="dashboard-grid-content">
                                    <h3>{{ dashboard.title }}</h3>
                                    <time>زمان‌ایجاد: {{ toRelative(dashboard.created_at) }}</time>
                                </div>
                                <div class="dashboard-grid-btns">
                                    <div class="tgju-btn" v-tooltip="'ویرایش'" @click.stop="goToRoute(dashboard.id)"><i class="uil uil-edit-alt"></i> ویرایش</div>
                                    <div class="tgju-btn tgju-btn-danger" v-tooltip="'حذف'" @click.stop="removeDashboard(dashboard.id)"><i class="uil uil-trash"></i> حذف</div>
                                </div>                    
                            </div>
                        </div>
                        <div class="col-dash col-12 col-md-6 col-lg-4 col-xl-2" @click.prevent="open_dashboard_add_modal()">
                            <div class="dashboard-grid-box all-dash">
                                <div class="dashboard-grid-all-dash">
                                    <div class="dashboard-grid-all-dash-icon"><i class="uil uil-plus-circle"></i></div>
                                    <div class="dashboard-grid-all-dash-text">ایجاد داشبورد</div>
                                </div>                    
                            </div>
                        </div>
                    </div>
                </div>
            </slot>
            <div v-if="!dashboards.length" class="dashboard-widget-tabs p-0">
                    <div class="sidebar-box">
                    <div  class="popup-dash-empty">
                        <div class="noWidget noContent">
                            <div>
                                <i class="uil uil-layer-group-slash noContent-icon" aria-hidden="true"></i>
                                <p class="noContent-title">در حال حاضر داشبوردی وجود ندارد</p>
                                <a href="" class="tgju-btn  m-top tgju-btn-primary" @click.prevent="open_dashboard_add_modal()" v-tooltip="'ایجاد داشبورد جدید'"><i class="uil uil-plus-circle" style="display: inline-block;"></i> ایجاد داشبورد</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </slot>
    </slot>

    <slot v-if="!grid">
        <div class="dashboard-widget-tabs p-0">
            <div class="sidebar-box">
                <slot v-if="loading">
                    <div class="tgju-widget-content full-padding">
                        <div class="text-center p-2">
                            <div class="widget-loading"></div>
                        </div>
                    </div>
                </slot>
                <slot v-else>
                    <slot v-if="dashboards.length">
                        <table class="widget-table widget-table-lg">
                            <thead>
                                <tr>
                                    <th>عنوان داشبورد</th>
                                    <th class="hide-640">زمان ایجاد</th>
                                    <th class="hide-table-r hide-640">زمان آخرین تغییر</th>
                                    <th class="w60 text-center">ویرایش</th>
                                    <th class="w60 text-center">حذف</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="pointer" v-for="dashboard in dashboards" :key="dashboard.id" @click.prevent="goToDashboardRoute(dashboard.id)">
                                    <td class="border-l">{{ dashboard.title }}</td>
                                    <td class="border-l hide-640">{{ toRelative(dashboard.created_at) }}</td>
                                    <td class="border-l hide-table-r hide-640">{{ toRelative(dashboard.updated_at) }}</td>
                                    <td class="w60 text-center border-l"><i class="uil uil-edit-alt table-icons pointer" v-tooltip="'ویرایش'" @click.stop="goToRoute(dashboard.id)"></i></td>
                                    <td class="w60 text-center"><i class="uil uil-trash table-icons red-color pointer" v-tooltip="'حذف'" @click.stop="removeDashboard(dashboard.id)"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </slot>
                    <div v-if="!dashboards.length" class="popup-dash-empty">
                        <div class="noWidget noContent">
                            <div>
                                <i class="uil uil-layer-group-slash noContent-icon" aria-hidden="true"></i>
                                <p class="noContent-title">در حال حاضر داشبوردی وجود ندارد</p>
                                <a href="" class="tgju-btn  m-top tgju-btn-primary" @click.prevent="open_dashboard_add_modal()" v-tooltip="'ایجاد داشبورد جدید'"><i class="uil uil-plus-circle" style="display: inline-block;"></i> ایجاد داشبورد</a>
                            </div>
                        </div>
                    </div>
                </slot>
            </div>
        </div>
    </slot>
</div>
</template>

<script>
// این ویو برای لیست داشبوردها استفاده میشود
import moment from "moment-jalaali";

export default {
    name: 'DashboardList',
    components: {},
    data: function () {
        return {
            grid:true,
            dashboards: [],
            dashboard: {
                id: null,
                user_id: null,
                title: '',
                type: null,
                default: false,
                created_at: null,
                updated_at: null,
            },
            loading: true,
        }
    },
    mounted() {
        // لود کردن لیست داشبورد های کاربر
        this.loadDashboards();

        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
    },
    methods: {
        cahnge_style(){
            if(this.grid == true){
                this.grid = false;
            }else{
                this.grid = true;
            }
        },
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).fromNow();
        },
        // این متد جهت واکشیه داشبورد ها از استور ویو جی اس استفاده میشود
        loadDashboards() {
            let dashboards = this.$store.state.dashboards;
            if (dashboards.length) {
                this.loading = false;
            }
            if (dashboards.length <= 0) {
                this.loading = false;
            }
            this.dashboards = dashboards;
        },
        // این متد جهت حذف داشبورد استفاده میشود
        removeDashboard(id) {
            this.$helpers.confirm('هشدار حذف!', {
                message: 'آیا از حذف این داشبورد اطمینان دارید؟',
            }).then((confirmed) => {
                if (confirmed) {
                    this.$helpers.makeRequest('DELETE', '/dashboard/delete/' + id).then(api_response => {
                        this.$helpers.api_alert(api_response);

                        if (api_response.status == 200) {
                            this.$store.commit('removeDashboard', { id: id });
                            this.$swal.fire({
                                icon: "success",
                                title: "داشبورد با موفقیت حذف شد",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "مشکلی در حذف داشبورد رخ داده است",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        }
                    });
                }
            });
        },
        // از این متد جهت تغییر روت کنونی به حالت ویرایش داشبورد انتخاب شده استفاده میشود
        goToRoute(id) {
            this.$router.push({ name: "DashboardEdit", params: { id } });
        },
        // از این متد جهت تغییر روت کنونی به حالت داشبورد انتخاب شده استفاده میشود
        goToDashboardRoute(id) {
            this.$router.push({ name: "Dashboard", params: { id } });
        },
        // از این متد برای باز کردن مودال افوزدن داشبورد جدید و هندل کردن درخواست استفاده میشود
        open_dashboard_add_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_manageDashboards_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'DashboardAdd.vue',
                config: {
                    title: 'افزودن داشبورد',
                    icon: 'uil uil-plus-circle with-icon',
                    smallModal: true,
                    data: {
                        dashboards: this.dashboards
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (response) => {
                if (response.action == 'addDashboard') {
                    this.$store.commit('addDashboard', { dashboard: response.data.dashboard });
                }
            });
        }, // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Dashboards.vue',
                title: 'داشبورد‌ها',
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
}
</script>
